.App {
  text-align: center;
  position: fixed; /* Set the App component to fixed position */
  top: 0;
  left: 0;
  width: 100vw; /* Use 100vw for full viewport width */
  height: 100vh; /* Use 100vh for full viewport height */
  overflow: hidden; /* Hide any overflow content outside the viewport */
}

.App-header {
  background-color: #282c34;
  min-height: 100%; /* Use 100% to fill the full screen height */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


@font-face {
  font-family: 'YourFontName';
  src: url('/public/blipfont.ttf') format('truetype'); /* Path relative to the public directory */
}

body {
  font-family: 'YourFontName', sans-serif; /* Apply the custom font to the body */
}
