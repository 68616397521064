/* TickerBanner.css */
.ticker-banner {
  overflow: hidden;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 40px;
  background-color: rgba(51, 51, 51, 0); /* Set alpha to 0 for full transparency */
  color: white;
  line-height: 2.0;
  padding-bottom: 10px;
}

.ticker-content {
  position: absolute;
  white-space: nowrap;
  animation: ticker 20s linear infinite;
  animation-delay: 1s; /* Adjust the delay as needed */
  transform: translateX(100%);
}

@keyframes ticker {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(-100%);
  }
}
