@keyframes letterFallAnimation {
    0% {
      transform: translateY(-100px);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
  
  .zoom-indicator {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 36px;
    font-weight: bold;
    height: 160%;
    line-height: 3.0;
    /* padding-left: 20px; */
    
  }


  



  
  .letter {
    animation: letterFallAnimation 1s ease;
    animation-fill-mode: forwards;
    opacity: 0;
    
  }
  
  .fall-in {
    animation-delay: 0s;
    animation-duration: 0.7s;
  }


  
  /* animation.css */
.zoom-indicator-container {
    position: absolute;
    top: 20%; /* Adjust this value to change the vertical position */
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    font-weight: bold;
    color: #fff;
    animation: fall-animation 3s linear infinite;
  }
  
  @keyframes fall-animation {
    0% {
      transform: translateY(-100%);
    }
    100% {
      transform: translateY(100%);
    }
  }


  @media (max-width: 767px) {
    .letter {
      font-size: 16px; /* Adjust the font size for mobile view */
    }
  }


 
  